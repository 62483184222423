module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-6SQBBZZSQN"],"gtagConfig":{"anonymize_ip":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"雪山深处","short_name":"雪山深处","lang":"zh","start_url":"/","background_color":"#ffffff","theme_color":"#009ba1","display":"minimal-ui","icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0ef39e2ecad79c4698e8a775c5b5a81e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-waline/gatsby-browser.js'),
      options: {"plugins":[],"serverURL":"https://www.talaxy.site/.netlify/functions/comment","emoji":["https://unpkg.com/@waline/emojis@1.2.0/tieba"]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"quality":85,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"isIconAfterHeader":true,"elements":["h2","h3"],"icon":"<Svg aria-hidden=\"true\" viewBox=\"0 0 512 512\" width=\"24\" height=\"24\">\n\t\t\t\t\t\t\t\t\t<path\n\t\t\t\t\t\t\t\t\t\tfill=\"none\"\n\t\t\t\t\t\t\t\t\t\tstroke-width=\"48\"\n\t\t\t\t\t\t\t\t\t\tstroke=\"#80808080\"\n\t\t\t\t\t\t\t\t\t\tstroke-linecap=\"round\"\n\t\t\t\t\t\t\t\t\t\tstroke-linejoin=\"round\"\n\t\t\t\t\t\t\t\t\t\td=\"M200.66 352H144a96 96 0 010-192h55.41M312.59 160H368a96 96 0 010 192h-56.66M169.07 256h175.86\" />\n\t\t\t\t\t\t\t\t</Svg>","offsetY":0,"className":"anchor"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
